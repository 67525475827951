import * as configApi from '../../../apis/configApi';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppConfig } from 'fdot-template-shared';

type SliceState = {
    loadingConfig: boolean;
    config: IAppConfig;
}

const configSlice = createSlice({
    name: 'app-config',
    initialState: {
        loadingConfig: false,
        config: {}
    } as SliceState,
    reducers: {
        updateConfig: (state: SliceState, action: PayloadAction<IAppConfig>) => {
            state.config.APPINSIGHTS_INSTRUMENTATIONKEY = action.payload.APPINSIGHTS_INSTRUMENTATIONKEY;
            state.config.ARCULUS_API_KEY = action.payload.ARCULUS_API_KEY;
            // state.config.ARCULUS_API_BASE_URL = action.payload.ARCULUS_API_BASE_URL;
            state.config.ENVIRONMENT = action.payload.ENVIRONMENT;
            return state;
        },
        loadingStarted: (state: SliceState) => {
            state.loadingConfig = true;
            return state;
        },
        loadingCompleted: (state: SliceState) => {
            state.loadingConfig = false;
            return state;
        }
    }
});

export const { updateConfig, loadingStarted, loadingCompleted } = configSlice.actions;
export default configSlice.reducer;

export const fetchConfig = () => async (dispatch: any) => {
    dispatch(loadingStarted());
    configApi.getConfig()
        .then(
            (config: IAppConfig) => {
                dispatch(updateConfig(config));
                dispatch(loadingCompleted());
            })
        .catch((error: any) => {
            console.log(error);
            dispatch(loadingCompleted())
        });
}