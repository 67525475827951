"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrackingTypeFilter = void 0;
var TrackingTypeFilter;
(function (TrackingTypeFilter) {
    TrackingTypeFilter["None"] = "None";
    TrackingTypeFilter["RequiresNewLabel"] = "Requires New Label";
    TrackingTypeFilter["LocationChanged"] = "Location Changed";
    TrackingTypeFilter["OrganizationChanged"] = "Organization Changed";
    TrackingTypeFilter["PropertyUniqueChanged"] = "PropertyUnique Changed";
    TrackingTypeFilter["Located"] = "Located";
    TrackingTypeFilter["Unlocated"] = "Unlocated";
    TrackingTypeFilter["NewSerialNumber"] = "New Serial Number";
    TrackingTypeFilter["Justified"] = "Justified";
    TrackingTypeFilter["ItemNumber"] = "ItemNumber";
})(TrackingTypeFilter = exports.TrackingTypeFilter || (exports.TrackingTypeFilter = {}));
