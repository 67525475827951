import React from 'react';
import './App.css';
import { ToastContainer } from 'react-toastify';
import { Router } from "react-router-dom"
import Routes from './routes';
import history from './utils/history';
import { initializeAxios } from './apis/axiosInit';
import { initializeIcons } from './Icons';
import "reflect-metadata";

const App: React.FC = (props) => {

  initializeAxios();
  initializeIcons();

  return (
    <>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        pauseOnHover={true}
        pauseOnFocusLoss={true}
      />
      <Router history={history} >
        <Routes></Routes>
      </Router>
    </>
  );
}

export default App;
