"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialize = exports.logException = exports.log = void 0;
var log = function () { };
exports.log = log;
var logException = function () { };
exports.logException = logException;
var initialize = function (logService) {
    exports.log = logService.log;
    exports.logException = logService.logException;
};
exports.initialize = initialize;
