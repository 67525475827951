import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { ILoggingService, logService, IAppConfig } from "fdot-template-shared";
import { staffService, IStaffServiceOptions, Environment } from '@fdot/arculus-staff-service';

export var appInsights: ApplicationInsights;

export const initializeApp = (config: IAppConfig, displayName: string) => {
    loadAppInsights(config.APPINSIGHTS_INSTRUMENTATIONKEY);
    appInsights.setAuthenticatedUserContext(displayName);
    initializeSharedServices(config);
}

const loadAppInsights = (applicationInsightsKey?: string) => {
    if (appInsights) {
        return;
    }

    if (process.env.APP_ENVIRONMENT === 'production' && applicationInsightsKey) {
        // Currently only logging to app insights for the client in production
        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: applicationInsightsKey,
                autoTrackPageVisitTime: true,
                enableAutoRouteTracking: true
            }
        });
        appInsights.loadAppInsights();
    }
    else {
        appInsights = {
            trackEvent: () => { },
            trackException: () => { },
            setAuthenticatedUserContext: () => { }
        } as any as ApplicationInsights;
    }
}


const initializeSharedServices = (config: IAppConfig) => {

    const cachingEnabled = fdot.process.env.STAFF_CACHE_ENABLED;
    let cacheDurationInMinutes = 0;
    if (cachingEnabled) {
        if (fdot.process.env.STAFF_CACHE_DURATION_IN_MINUTES) {
            cacheDurationInMinutes = fdot.process.env.STAFF_CACHE_DURATION_IN_MINUTES;
        }
    }

    if (config.ARCULUS_API_KEY) {
        const staffServiceOptions: IStaffServiceOptions = {
            arculusApiKey: config.ARCULUS_API_KEY,
            environment: process.env.APP_ENVIRONMENT === "production"
                ? Environment.Production
                : Environment.Test,
            cacheOptions: {
                cacheDurationInMinutes,
                cachingEnabled,
                slidingExpirationEnabled: true
            }
        };

        staffService.initialize(staffServiceOptions);
    }

    const loggingService: ILoggingService = {
        log: (item: any) => console.log(item),

        logException: (error: any) => {
            const exceptionDetails = {
                error,
                id: 'Exception',
                severityLevel: SeverityLevel.Critical,
            };
            appInsights.trackException(exceptionDetails);
        }
    }

    logService.initialize(loggingService);
};