import React, { useState, CSSProperties } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../models/redux/rootReducer';
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from 'reactstrap';


const Header = () => {

    const dropDownMenuItemStyle: CSSProperties = {
        color: 'black'
    };

    const headerTextStyle: CSSProperties = {
        color: 'white',
        padding: '10px',
    };

    const brandStyle: CSSProperties = {
        paddingRight: 20
    }

    const navbarStyles : CSSProperties = {
        backgroundColor:"#33557C"
    }

    const [isExpanded, setIsExpanded] = useState(false);
    const { isAuthenticated, user } = useSelector((state: RootState) => state.userReducer);

    const toggle = () => setIsExpanded(!isExpanded);
    const isImpersonating = user && user.srsId !== user.actualUserSrsId;
    
    const getLoginOrSignOutLink = () => {
        const loginUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/login`;
        const logoutUrl = `${fdot.process.env.BACKEND_SERVER_HOST}/logout`;

        return isAuthenticated
            ? (<a className='nav-link' href={logoutUrl} style={headerTextStyle}>Sign Out</a>)
            : (<a className='nav-link' href={loginUrl} style={headerTextStyle}>Log In</a>);
    }

    const getLoggedInUserDisplay = () => {
        return isAuthenticated
            ? (
                <>
                    <div className="d-none d-xl-block" style={headerTextStyle}>
                        {isImpersonating ? (
                            <span className="mx-2">
                                Impersonating: {user!.displayName}
                            </span>
                        )
                            : (
                                <span className="mx-2">
                                    Welcome: {user!.displayName}
                                </span>
                            )
                        }
                    </div>
                </>
            )
            : '';
    }

    // const getFeatureOptions = () => {
    //     if (isAuthenticated) {
    //         return (
    //             <UncontrolledDropdown nav inNavbar>
    //                 <DropdownToggle nav caret>
    //                     Features
    //                 </DropdownToggle>
    //                 <DropdownMenu>
    //                     {getTestEnvironmentOptions()}
    //                     <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Staff'}>
    //                         Staff
    //                     </DropdownItem>
    //                 </DropdownMenu>
    //             </UncontrolledDropdown>)
    //     }
    //     return null;
    // };

    const getAssetsOptions = () => {
        if (!isAuthenticated || !user || (!user.isAdmin)) { return null;}
        return (
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                    Assets
                </DropdownToggle>
                <DropdownMenu>                                    
                    <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/CreateNew'}>
                        Create New
                    </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>)
    };

    // const getTestEnvironmentOptions = () => {
    //     if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
    //         return (
    //             <>
    //                 <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Impersonate'}>
    //                     Impersonation
    //                 </DropdownItem>
    //             </>
    //         );
    //     }
    //     return null;
    // }

    const getAdministrationOptions = () => {        
        if (!isAuthenticated || !user || (!user.isAdmin)) { return null;}
        return (
          <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav caret>
              Admin
            </DropdownToggle>
            <DropdownMenu>              
              {getAdminRoleOnlyOptions()}
            </DropdownMenu>
          </UncontrolledDropdown>
        );
      }

      const getAdminRoleOnlyOptions = () => {
        if (!user || !user.isAdmin) return null;
        return (
          <>
            <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Admin/Import'}>
              Import From Flair
            </DropdownItem>            
            <DropdownItem style={dropDownMenuItemStyle} tag={Link} to={'/Admin/Inventories'} >
                Inventories
            </DropdownItem>
          </>
        );
      }

    const getNavbar = () => {
        return (
            <div>
                <Navbar dark expand="md" style={navbarStyles}>
                    <NavbarBrand href="/" style={brandStyle}>
                        <img src="https://webincludes.dot.state.fl.us/images/FDOTLogowhitetranssm.png" alt="fdot logo"/>
                        <span title="Asset Management Inventory System">
                            AMIS
                        </span>
                    </NavbarBrand>
                    <NavbarToggler onClick={toggle} />
                    <Collapse isOpen={isExpanded} navbar>
                        {/* <Nav navbar>
                            {getAssetsOptions()}
                        </Nav> */}
                        <Nav navbar>
                            {getAdministrationOptions()}
                        </Nav>
                        <Nav className="ms-auto">
                            <NavItem>
                                {getLoggedInUserDisplay()}
                            </NavItem>
                            <NavItem>
                                {getLoginOrSignOutLink()}
                            </NavItem>                            
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        );
    }

    return getNavbar();
};

export default Header;