import React, { useEffect, Suspense, CSSProperties } from "react";
import { fetchConfig } from "./models/redux/slices/configSlice";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from './models/redux/rootReducer'
import { LoadingImage } from "./components/common/loadingImage";
import { fetchUser } from "./models/redux/slices/userSlice";
import Loading from "react-loading";
import { Switch, Route } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import { initializeApp } from "./utils/initializer";

const Home = React.lazy(() => import('./components/home'));
// const LandingPage = React.lazy(() => import('./components/landingPage'));
const NotAuthorized = React.lazy(() => import('./components/notAuthorized'));
const NoMatch = React.lazy(() => import('./components/noMatch'));
const Staff = React.lazy(() => import('./components/staff/staff'));
const Imports = React.lazy(() => import('./components/imports/index'));
const Exports = React.lazy(() => import('./components/imports/index'));
const Inventories = React.lazy(() => import('./components/inventories/index'));
const Assets = React.lazy(()=> import('./components/assets/index'))

const hiddenButScreenReaderAccessibleStyle: CSSProperties = {
    position: 'absolute',
    left: '-10000px',
    top: 'auto',
    width: '1px',
    height: '1px',
    overflow: 'hidden'
}

const Routes: React.FC = () => {

    const dispatch = useDispatch();

    const { loadingConfig, config } = useSelector(
        (state: RootState) => state.configReducer
    );

    const { isUserLoaded, isAuthenticated, user } = useSelector(
        (state: RootState) => state.userReducer
    );

    useEffect(() => {
        dispatch(fetchUser());
    }, [dispatch]);

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(fetchConfig());
        }
    }, [dispatch, isAuthenticated]);

    useEffect(() => {
        if (config && user) {
            initializeApp(config, user.displayName)
        }
    }, [config, user]);

    if (!isUserLoaded || loadingConfig) {
        return (
            <LoadingImage></LoadingImage>
        );
    }


    const getMainLandingPage = (): JSX.Element => {
        if (isAuthenticated) {
            return (<Route exact path='/' component={Assets} />);
        }
        else {
            return (<Route exact path='/' component={Home} />);
        }
    }

    const getAuthenticatedRoutes = (): JSX.Element[] => {
        if (!isAuthenticated) return [];
        const routes: Array<JSX.Element> = [];
        routes.push(<Route path='/Assets' key="Assets" exact component={Assets} />);
        routes.push(<Route exact path='/CreateNew' component={Home} key="CreateNew"/>);
        routes.push(<Route exact path='/EditAsset' component={Home} key="EditAsset"/>);
        routes.push(<Route path="/Staff" key="Staff" exact component={Staff} />);
        return routes;
    }

    const getAdminRoutes = (): JSX.Element[] => {
        if (!user || (!user.isAdmin)) { return [];}
        const routes: Array<JSX.Element> = [];
        routes.push(<Route exact path='/Admin/Import' key="Imports" component={Imports} />);
        // routes.push(<Route exact path='/Assets' key="Assets" component={Assets} />);
        routes.push(<Route exact path='/Admin/Export' key="Exports" component={Exports} />);
        routes.push(<Route exact path='/Admin/Inventories' key="Inventories" component={Inventories} />);
        return routes;
    }

    return (
        <>
            <a href="#maincontent" style={hiddenButScreenReaderAccessibleStyle}>
                Skip to main content
            </a>
            <Header></Header>
            <div id="maincontent" style={{ paddingBottom: '100px' }}>
                <div className="container-fluid">
                    <div className='row'>
                        <div role='main' className='col'>
                            <Suspense fallback={<Loading></Loading>}>
                                <Switch>
                                    {getMainLandingPage()}

                                    <Route path="/NotAuthorized" component={NotAuthorized}></Route>

                                    {getAuthenticatedRoutes()}
                                    {getAdminRoutes()}
                                    <Route component={NoMatch} />
                                </Switch>
                            </Suspense>
                        </div >
                    </div>
                </div>
            </div>

            <Footer></Footer>
        </>
    );
}

export default Routes;