import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import * as inventoryApi from './../../apis/inventoryApi';
// import {IAssetDetail, IInventory} from 'fdot-template-shared';
type SliceState = {    
}

const inventorySlice = createSlice({
    name: 'inventory',
    initialState: { 
        
    } as SliceState,
    reducers: {
        createInventory: (state: SliceState, action: PayloadAction<any>) => {            
            return state;
        }
    }
});

export const { createInventory } = inventorySlice.actions;
export default inventorySlice.reducer;