"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnfoundReasonEnum = void 0;
var UnfoundReasonEnum;
(function (UnfoundReasonEnum) {
    UnfoundReasonEnum["Lost1"] = "Lost1";
    UnfoundReasonEnum["Lost2"] = "Lost2";
    UnfoundReasonEnum["Stolen"] = "Stolen";
    UnfoundReasonEnum["Destroyed"] = "Destroyed";
    UnfoundReasonEnum["Surplused"] = "Surplused";
    UnfoundReasonEnum["SiteVerified"] = "Site Verified";
})(UnfoundReasonEnum = exports.UnfoundReasonEnum || (exports.UnfoundReasonEnum = {}));
