"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.cacheAccessToken = exports.getCachedAccessToken = exports.cacheObject = exports.getCachedObject = exports.staffUtils = exports.phoneNumberUtils = exports.axiosUtils = exports.logService = void 0;
__exportStar(require("./models/configs/IAppConfig"), exports);
__exportStar(require("./models/configs/IArculusConfig"), exports);
__exportStar(require("./models/configs/IStaffCacheConfig"), exports);
__exportStar(require("./models/IStaff"), exports);
__exportStar(require("./models/IUser"), exports);
__exportStar(require("./models/IAssetDetail"), exports);
__exportStar(require("./models/IInventory"), exports);
__exportStar(require("./models/IAssetDetailScanItem"), exports);
__exportStar(require("./models/IOrgCode"), exports);
__exportStar(require("./models/ILocation"), exports);
__exportStar(require("./models/IUserCostCenter"), exports);
__exportStar(require("./models/IAmisStaffMember"), exports);
__exportStar(require("./models/ITracking"), exports);
__exportStar(require("./models/ITrackingItem"), exports);
__exportStar(require("./models/IAttachment"), exports);
__exportStar(require("./models/IFilterCriteria"), exports);
__exportStar(require("./models/UnfoundReasonEnum"), exports);
__exportStar(require("./services/ILoggingService"), exports);
var logService = __importStar(require("./services/logService"));
exports.logService = logService;
var axiosUtils = __importStar(require("./utils/axiosUtils"));
exports.axiosUtils = axiosUtils;
var phoneNumberUtils = __importStar(require("./utils/phoneNumberUtils"));
exports.phoneNumberUtils = phoneNumberUtils;
var staffUtils = __importStar(require("./utils/staffUtils"));
exports.staffUtils = staffUtils;
var cacheService_1 = require("./services/cacheService");
Object.defineProperty(exports, "getCachedObject", { enumerable: true, get: function () { return cacheService_1.getCachedObject; } });
Object.defineProperty(exports, "cacheObject", { enumerable: true, get: function () { return cacheService_1.cacheObject; } });
Object.defineProperty(exports, "getCachedAccessToken", { enumerable: true, get: function () { return cacheService_1.getCachedAccessToken; } });
Object.defineProperty(exports, "cacheAccessToken", { enumerable: true, get: function () { return cacheService_1.cacheAccessToken; } });
