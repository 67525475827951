"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.staffMap = void 0;
var arculus_staff_service_1 = require("@fdot/arculus-staff-service");
var title_case_1 = require("title-case");
var staffMap = function (staff) {
    return {
        srsId: staff.id,
        emailAddress: staff.emailAddress,
        azureAdOid: staff.azureAdOid,
        firstName: staff.firstName,
        lastName: staff.lastName,
        racfId: staff.racfId,
        phoneNumber: arculus_staff_service_1.phoneNumberUtils.formatPhoneNumber(staff.phone, staff.phoneExtension),
        positionWorkTitle: staff.activeDirectoryWorkingTitle !== undefined && staff.activeDirectoryWorkingTitle !== null
            ? (0, title_case_1.titleCase)(staff.activeDirectoryWorkingTitle)
            : '',
        organizationalCode: staff.organizationalCode,
        staffConnectors: staff.staffConnectors
    };
};
exports.staffMap = staffMap;
