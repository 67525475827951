"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatPhoneNumber = void 0;
var logService_1 = require("./../services/logService");
var formatPhoneNumber = function (phoneNumber, extension) {
    if (phoneNumber === undefined) {
        return '';
    }
    if (phoneNumber.length === 10) {
        var formatted = "".concat(phoneNumber.substr(0, 3), "-").concat(phoneNumber.substr(3, 3), "-").concat(phoneNumber.substr(6));
        return extension !== undefined && extension.length > 0
            ? "".concat(formatted, " (").concat(extension, ")")
            : formatted;
    }
    else if (phoneNumber.length === 7) {
        var formatted = "".concat(phoneNumber.substr(0, 3), "-").concat(phoneNumber.substr(3, 4));
        return extension !== undefined && extension.length > 0
            ? "".concat(formatted, " (").concat(extension, ")")
            : formatted;
    }
    else {
        (0, logService_1.logException)("Implement formatPhoneNumber logic for: ".concat(phoneNumber));
        return phoneNumber;
    }
};
exports.formatPhoneNumber = formatPhoneNumber;
