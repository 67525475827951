import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser } from "fdot-template-shared";
import * as userApi from '../../../apis/userApi';

type SliceState = {
    isUserLoaded: boolean;
    isAuthenticated: boolean;
    user?: IUser;
}

const userSlice = createSlice({
    name: 'user',
    initialState: {
        isUserLoaded: false,
        isAuthenticated: false,
        user: undefined
    } as SliceState,
    reducers: {
        updateUser: (state: SliceState, action: PayloadAction<IUser>) => {
            state.user = action.payload;
            state.isAuthenticated = action.payload !== undefined &&
                action.payload !== null &&
                action.payload.srsId !== undefined;
            state.isUserLoaded = true;
            return state;
        },
        userLoaded: (state: SliceState, action: PayloadAction<boolean>) => {
            state.isUserLoaded = action.payload;
            return state;
        },
    }
});

export const { updateUser, userLoaded } = userSlice.actions;
export default userSlice.reducer;

export const fetchUser = () => async (dispatch: any) => {
    userApi.getUser()
        .then(
            (user: IUser) => {
                dispatch(updateUser(user));
                dispatch(userLoaded(true));
            })
        .catch((error: any) => {
            console.log(error);
        });
}

export const impersonateUser = (
    srsId: number,
    successCallback?: () => void,
    failCallback?: () => void) => async (dispatch: any) => {
        userApi.impersonate(srsId)
            .then((user: IUser) => {
                dispatch(updateUser(user));
                if (successCallback) {
                    successCallback();
                }
            })
            .catch((error: any) => {
                console.log(error);
                if (failCallback) {
                    failCallback();
                }
            });
    }