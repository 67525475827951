import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import store from './models/redux/store';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//     </Provider>,
//   </React.StrictMode>
// );

const render = () =>
{
  const App = require('./App').default

  root.render(
    // <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>,
    // </React.StrictMode>
  );
}

render();

if (fdot.process.env.APP_ENVIRONMENT === 'development' && (module as any).hot) {
  (module as any).hot.accept('./App', render)
}


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
