import { combineReducers } from "@reduxjs/toolkit";
import configReducer from './slices/configSlice';
import userReducer from './slices/userSlice';
import inventoryReducer from './slices/inventorySlice';

const rootReducer = combineReducers({
    configReducer,
    userReducer,
    inventoryReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;